import { computed } from '@vue/composition-api'

import { root } from '@/main'

export const useAuth = (context) => {
  const isMaster = computed(() => {
    return root.$store.getters.isMaster
  })

  const isAdmin = (id) => {
    return isMaster.value || root.$store.getters.isStbMWk(id)
  }

  const isWkDiscipline = (id, did) => {
    return isAdmin(id) || root.$store.getters.isStbMWkDiscipline(id, did)
  }

  const isTeam = (id) => {
    return isMaster.value || root.$store.getters.isTeam(id)
  }

  const isClub = (id) => {
    return isMaster.value || root.$store.getters.isClub(id)
  }

  return { isMaster, isAdmin, isWkDiscipline, isTeam, isClub }
}
